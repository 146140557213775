import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Image from 'next/image';

import { withTranslation } from 'language/i18n';
import customersSaysData from 'constants/customersSaysData.json';
import Item from './Item';

const CustomersSays = ({ t, classNameForBG }) => {
  const sliderRef = useRef();

  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 500) {
      setSlidesToShow(1);
    }
  }, []);

  const sliderSettings = {
    arrows: false,
    infinite: true,
    autoPlaySpeed: 1000,
    autoplay: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };

  const slideNext = () => {
    sliderRef.current.slickNext();
  };

  const slidePrevious = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className={classNameForBG}>
      <h2 className="section__header__title">{t('whatiyiMakinaCustomersSay')}</h2>
      <div className="container">
        <div className="im-slider-container">
          <div className="im-slider">
            <Slider {...sliderSettings} ref={sliderRef}>
              {
                _.map(customersSaysData, (data, index) => (
                  <Item data={data} key={index} />
                ))
              }

            </Slider>
          </div>
        </div>
        <div className="col-15" style={{ marginTop: 45 }}>
          <div className="row">
            <div className="customers-says-slider-arrow" onClick={() => slidePrevious()}>
              <Image
                src="/image/icons/ic-back-yellow.svg"
                width="40"
                height="30"
              />
            </div>
            <div className="customers-says-slider-arrow" style={{ marginLeft: 60 }} onClick={() => slideNext()}>
              <Image
                src="/image/icons/ic-forward-yellow.svg"
                width="40"
                height="30"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CustomersSays.propTypes = {
  classNameForBG: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation('common')(CustomersSays);
