import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

const CustomersSays = ({ data }) => {
  return (
    <div className="testimonial-slider__item">
      <div className="d-flex align-items-center justify-content-between w-100">
        <div>
          <div className="testimonial-slider__item__title">{data.fullName}</div>
          <div className="testimonial-slider__item__description">{data.title}</div>
        </div>
        <div>
          <Image
            src={data.image}
            width="37"
            height="40"
          />
        </div>
      </div>
      <div className="mt-30">
        <blockquote className="testimonial-slider__item__quote">
          {data.description}
        </blockquote>
      </div>
    </div>
  );
};

CustomersSays.propTypes = {
  data: PropTypes.object,
};

export default CustomersSays;
